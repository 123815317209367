import React from 'react';
import styled from 'styled-components';

import withRoot from '../withRoot';
import Container from './Container';

import LeftBorderImg from '../images/left_border.png';
import RightBorderImg from '../images/right_border.png';
import CampusImg from '../images/new/campus_pro.jpg';
import Navbar from './Navbar';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import Menu from './Menu';
import { observer } from 'mobx-react';
import { observable, action, runInAction } from 'mobx';

const BorderLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 300px;
  background: url(${LeftBorderImg});
  background-repeat: repeat-y;
  background-position-x: left;
  background-position-y: top;
  background-size: 7vw;
`;

const BorderRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px;
  background: url(${RightBorderImg});
  background-repeat: repeat-y;
  background-position-x: right;
  background-position-y: top;
  background-size: 7vw;
`;

const Borders = styled.div`
  position: absolute;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  top: 0;
  z-index: -1;
  @media (max-width: 1250px) {
    display: none;
  }
`;

// tslint:disable-next-line:no-empty-interface
interface Props {
  isCreatePage?: boolean;
}

interface State {
  scrolled: boolean;
  email: string;
}

@observer
class Layout extends React.Component<Props, State> {
  @observable
  public menuOpen: boolean = false;

  @observable
  public scrolled: boolean = false;

  @action
  public componentDidMount() {
    this.scrolled = window.scrollY > 10;

    window.onscroll = () =>
      runInAction(() => {
        this.scrolled = window.scrollY > 10;
      });
  }

  public render() {
    const { children } = this.props;
    return (
      <div style={{ position: 'relative' }}>
        <Helmet>
          <link
            rel="apple-touch-icon"
            sizes="57x57"
            href="https://lfai.co/favicons/apple-touch-icon-57x57.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="60x60"
            href="https://lfai.co/favicons/apple-touch-icon-60x60.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="72x72"
            href="https://lfai.co/favicons/apple-touch-icon-72x72.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="76x76"
            href="https://lfai.co/favicons/apple-touch-icon-76x76.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="114x114"
            href="https://lfai.co/favicons/apple-touch-icon-114x114.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="120x120"
            href="https://lfai.co/favicons/apple-touch-icon-120x120.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="144x144"
            href="https://lfai.co/favicons/apple-touch-icon-144x144.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="152x152"
            href="https://lfai.co/favicons/apple-touch-icon-152x152.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="https://lfai.co/favicons/apple-touch-icon-180x180.png"
          />
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 1)"
            href="https://lfai.co/favicons/apple-touch-startup-image-320x460.png"
          />
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 2)"
            href="https://lfai.co/favicons/apple-touch-startup-image-640x920.png"
          />
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
            href="https://lfai.co/favicons/apple-touch-startup-image-640x1096.png"
          />
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
            href="https://lfai.co/favicons/apple-touch-startup-image-750x1294.png"
          />
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 414px) and (device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)"
            href="https://lfai.co/favicons/apple-touch-startup-image-1182x2208.png"
          />
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 414px) and (device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)"
            href="https://lfai.co/favicons/apple-touch-startup-image-1242x2148.png"
          />
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 1)"
            href="https://lfai.co/favicons/apple-touch-startup-image-748x1024.png"
          />
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 1)"
            href="https://lfai.co/favicons/apple-touch-startup-image-768x1004.png"
          />
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)"
            href="https://lfai.co/favicons/apple-touch-startup-image-1496x2048.png"
          />
          <link
            rel="apple-touch-startup-image"
            media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)"
            href="https://lfai.co/favicons/apple-touch-startup-image-1536x2008.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="https://lfai.co/favicons/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="https://lfai.co/favicons/favicon-16x16.png"
          />
          <link
            rel="shortcut icon"
            href="https://lfai.co/favicons/favicon.ico"
          />
          <meta
            data-react-helmet="true"
            name="description"
            content=" Manager votre innovation n’a jamais été aussi simple. Engagez tous vos collaborateurs et boostez votre activité.  Développez l’intrapreneuriat dans votre entreprise."
          />
          <meta
            data-react-helmet="true"
            itemProp="name"
            content="Campus - La Fabrique à Innovations"
          />
          <meta
            data-react-helmet="true"
            itemProp="description"
            content=" Manager votre innovation n’a jamais été aussi simple. Engagez tous vos collaborateurs et boostez votre activité.  Développez l’intrapreneuriat dans votre entreprise."
          />
          <meta data-react-helmet="true" itemProp="image" content={CampusImg} />
          <meta
            data-react-helmet="true"
            name="twitter:card"
            content="summary_large_image"
          />
          <meta
            data-react-helmet="true"
            name="twitter:site"
            content="@_lafabrique"
          />
          <meta
            data-react-helmet="true"
            name="twitter:title"
            content="Campus - La Fabrique à Innovations "
          />
          <meta
            data-react-helmet="true"
            name="twitter:description"
            content=" Manager votre innovation n’a jamais été aussi simple. Engagez tous vos collaborateurs et boostez votre activité.  Développez l’intrapreneuriat dans votre entreprise."
          />
          <meta
            data-react-helmet="true"
            name="twitter:creator"
            content="@_lafabrique"
          />
          <meta
            data-react-helmet="true"
            name="twitter:image:src"
            content={CampusImg}
          />
          <meta
            data-react-helmet="true"
            property="og:title"
            content="Campus - La Fabrique à Innovations "
          />
          <meta data-react-helmet="true" property="og:type" content="article" />
          <meta
            data-react-helmet="true"
            property="og:url"
            content="https://campus.lfai.co"
          />
          <meta
            data-react-helmet="true"
            property="og:image"
            content={CampusImg}
          />
          <meta
            data-react-helmet="true"
            property="og:description"
            content=" Manager votre innovation n’a jamais été aussi simple. Engagez tous vos collaborateurs et boostez votre activité.  Développez l’intrapreneuriat dans votre entreprise."
          />
          <meta
            data-react-helmet="true"
            property="og:site_name"
            content="Campus- La Fabrique à Innovations"
          />
          <meta
            data-react-helmet="true"
            property="fb:admins"
            content="379669732115141"
          />
          <script
            defer
            src="https://use.fontawesome.com/releases/v5.3.1/js/all.js"
          />
          <script
            id="ze-snippet"
            src="https://static.zdassets.com/ekr/snippet.js?key=e9bda10b-b02b-49bd-8eb9-0fbf557bce4a"
          />
          <title>Campus - La Fabrique à Innovations</title>
          <script type="text/javascript">{`_linkedin_partner_id = "632793";
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}</script>
          <script type="text/javascript">{`(function(){var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})();`}</script>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=AW-797980379"
          />
          <script type="text/javascript">{`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-797980379');
                function gtag_create(url) {
                  var callback = function () {
                    return false;
                  };
                  gtag('event', 'conversion', { 'send_to': 'AW-797980379/SGJECM7c6JIBENvtwPwC', 'event_callback': callback });
                }

                function gtag_contact(url) {
                  var callback = function () {
                    return false;
                  };
                  gtag('event', 'conversion', { 'send_to': 'AW-797980379/6uBnCPv59okBENvtwPwC', 'event_callback': callback });
                }`}</script>
        </Helmet>
        <Borders>
          <BorderLeft />
          <BorderRight />
        </Borders>
        <Container>
          {this.menuOpen && (
            <Menu
              isCreatePage={this.props.isCreatePage}
              closeMenu={() =>
                runInAction(() => {
                  this.menuOpen = false;
                })
              }
            />
          )}
          <Navbar
            openMenu={() =>
              runInAction(() => {
                this.menuOpen = true;
              })
            }
            scrolled={this.scrolled}
            isCreatePage={this.props.isCreatePage}
          />
          {children}
          <div style={{ flexGrow: 1 }} />
          {!this.props.isCreatePage && <Footer />}
        </Container>
      </div>
    );
  }
}

export default withRoot(Layout);
