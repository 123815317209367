import React from 'react';
import styled from 'styled-components';
import theme from '../theme';
import { Typography } from '@material-ui/core';

const Socials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  > * {
    cursor: pointer;
    margin: 15px;
    color: ${theme.palette.blue.main};
    font-size: 25px;
    &:hover {
      color: ${theme.palette.blue.main};
    }
  }
`;

const Copyright = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 70px;
  * {
    font-weight: 300 !important;
    color: #003466 !important;
  }
`;

const FooterWrapper = styled.footer``;

class Footer extends React.PureComponent {
  public render() {
    return (
      <FooterWrapper>
        <Socials>
          <a
            href="https://twitter.com/_lafabrique"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter" />
          </a>
          <a
            href=" https://www.youtube.com/channel/UCg96WHjirqrdhpQ4-kyQIfA"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-youtube" />
          </a>
          <a
            href="https://www.linkedin.com/company/lafabriqueainnovations/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin" />
          </a>
        </Socials>
        <Copyright>
          <Typography style={{ fontSize: '17px' }}>
            La Fabrique à Innovations
          </Typography>
          <Typography style={{ fontSize: '17px' }} align="right">
            © 2014 - {new Date().getFullYear()} Innovative Community
          </Typography>
        </Copyright>
      </FooterWrapper>
    );
  }
}

export default Footer;
