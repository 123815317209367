import { Typography } from '@material-ui/core';
import GatsbyLink from 'gatsby-link';
import React from 'react';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import AcademyImage from '../images/academie.jpg';
import DashboardImage from '../images/analytics.jpg';
import ChallengesImage from '../images/hackathon.jpg';
import IdeaboxImage from '../images/idea.jpg';
import IntraImage from '../images/intra.jpg';
import OpenInnoImage from '../images/openinno.jpg';
import ProjectImage from '../images/projet.jpg';
import theme from '../theme';

const NavbarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  width: 1100px;
  max-width: 100%;
  padding: 20px;
  transform: translateX(-50%);
  z-index: 100;
  background-color: white;
  transition: padding 0.1s;

  ${theme.breakpoints.down('sm')} {
    padding: 16px 20px;
  }

  &.scrolled {
    width: 100%;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    padding: 7px 20px;
  }

  nav {
    margin: 0 auto;
    max-width: 1060px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

const Links = styled(Typography)`
  &&& {
    width: 100%;
    margin-left: 30px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .link {
    white-space: nowrap;
    margin-right: 30px;
    font-size: 15px;
    text-decoration: none;
    position: relative;
    color: ${theme.palette.blue.main};
    &:last-child {
      margin-right: 0;
    }

    &:hover {
      border-bottom: 1px solid ${theme.palette.blue.main};
    }

    &.selected {
      border-bottom: 1px solid ${theme.palette.primary.main}!important;
      color: ${theme.palette.primary.main};
      svg {
        color: ${theme.palette.primary.main}!important;
      }
    }

    ${theme.breakpoints.down('sm')} {
      margin-right: 10px;
      font-size: 15px;
    }

    .menu-wrapper {
      display: none;
      position: absolute;
      z-index: 1;

      padding: 15px;
      margin-left: -10px;

      .link-menu {
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 15px;
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.1);
        white-space: nowrap;

        a {
          display: flex;
          border: 1px solid transparent;
          margin-bottom: 10px;
          font-size: 17px;
          text-decoration: none;
          color: ${theme.palette.grey[600]};
          padding: 10px 20px;
          border-radius: 4px;
          align-items: center;

          div {
            display: flex!important;
            flex-direction: column;
          }

          img {
            width: 30px;
            margin-right: 25px;
          }

          &:hover {
            /* color: ${theme.palette.primary.main}; */
            border: 1px solid ${theme.palette.grey[200]};
            background-color: ${theme.palette.grey[50]};
          }

          &:last-child {
            margin-bottom: 0;
          }

          .sub {
            font-size: 14px;
          }
        }
      }
    }

    &:hover {
      .menu-wrapper {
        display: block!important;
      }
    }
  }

  .create {
    color: ${theme.palette.primary.main};
    &:hover {
      border-bottom: 1px solid ${theme.palette.primary.main};
    }
  }

  .demo {
    background-color: ${theme.palette.primary.main};
    color: white;
    padding: 5px 12px;
    border-radius: 4px;

    &:hover {
      border-bottom: rgba(0, 0, 0, 0, .3);
    }
  }
`;

const Logo = styled.img`
  height: 50px;

  ${theme.breakpoints.down('sm')} {
    height: 40px;
  }
`;

interface Props {
  scrolled: boolean;
  isCreatePage?: boolean;
  openMenu: () => void;
}

class Navbar extends React.PureComponent<Props> {
  public createLink = (props: any) => <GatsbyLink to="/demo" {...props} />;

  public render() {
    const { scrolled, isCreatePage } = this.props;
    const path =
      typeof window !== 'undefined' ? window.location.pathname : '/unknown';
    return (
      <NavbarWrapper className={scrolled ? 'scrolled' : ''}>
        <nav>
          <GatsbyLink to="/">
            <Logo src="https://lfai.co/static/logo_complet-6811c967177a20250b60188d1c26686d.png" />
          </GatsbyLink>
          <Links component="div" className="desktop-only">
            <a
              className={`link desktop-only ${path.startsWith('/features') &&
                'selected'}`}
              id="anchor"
              style={{
                cursor: 'pointer',
                borderBottom: 'none',
                whiteSpace: 'nowrap',
              }}
            >
              Fonctionnalités
              <i
                className="fas fa-angle-down fa-fw"
                style={{ marginLeft: '5px', color: 'rgba(0, 51, 102, 0.5)' }}
              />
              <div className="menu-wrapper" style={{ display: 'none' }}>
                <div className="link-menu">
                  <GatsbyLink to="/features/ideabox">
                    <img src={IdeaboxImage} />
                    <div>
                      <span style={{ color: theme.palette.secondary.main }}>
                        Boite à idées
                      </span>
                      <span className="sub">
                        Boostez la créativité de vos collaborateurs
                      </span>
                    </div>
                  </GatsbyLink>
                  <GatsbyLink to="/features/lab">
                    <img src={ProjectImage} />
                    <div>
                      <span style={{ color: theme.palette.green.main }}>
                        Lab
                      </span>
                      <span className="sub">
                        Évaluez la faisabilité des meilleures idées
                      </span>
                    </div>
                  </GatsbyLink>
                  <GatsbyLink to="/features/intra">
                    <img src={IntraImage} />
                    <div>
                      <span style={{ color: theme.palette.primary.main }}>
                        Intrapreneuriat
                      </span>
                      <span className="sub">
                        Formez et structurer vos équipes intrapreneuriales
                      </span>
                    </div>
                  </GatsbyLink>
                  <GatsbyLink to="/features/academy">
                    <img src={AcademyImage} />
                    <div>
                      <span style={{ color: theme.palette.blue.main }}>
                        Académie
                      </span>
                      <span className="sub">
                        Facilitez le partage de compétences internes
                      </span>
                    </div>
                  </GatsbyLink>
                  <GatsbyLink to="/features/challenges">
                    <img src={ChallengesImage} />
                    <div>
                      <span style={{ color: theme.palette.secondary.main }}>
                        Challenges
                      </span>
                      <span className="sub">
                        Organisez des challenges avec vos collaborateurs
                      </span>
                    </div>
                  </GatsbyLink>
                  <GatsbyLink to="/features/openinno">
                    <img src={OpenInnoImage} />
                    <div>
                      <span style={{ color: theme.palette.green.main }}>
                        Open Innovation
                      </span>
                      <span className="sub">
                        Organisez des challenges externes
                      </span>
                    </div>
                  </GatsbyLink>
                  <GatsbyLink to="/features/dashboard">
                    <img src={DashboardImage} />
                    <div>
                      <span style={{ color: theme.palette.primary.main }}>
                        Tableau de bord
                      </span>
                      <span className="sub">
                        Pilotez facilement vos démarches d’innovation
                      </span>
                    </div>
                  </GatsbyLink>
                </div>
              </div>
            </a>
            <GatsbyLink
              to="/resources"
              className={`link ${path.startsWith('/resources') && 'selected'}`}
            >
              Ressources
            </GatsbyLink>
            <a
              href="https://medium.com/@1539768974147"
              target="_blank"
              className="link"
            >
              Blog
            </a>
            <GatsbyLink
              to="/contact"
              className={`link ${path.startsWith('/contact') && 'selected'}`}
            >
              Contact
            </GatsbyLink>
            <div style={{ flexGrow: 1 }} />
            {!isCreatePage && (
              <GatsbyLink
                to="/login"
                className={`link ${path.startsWith('/login') && 'selected'}`}
              >
                Connexion
              </GatsbyLink>
            )}
            <MediaQuery minWidth="1280px">
              {matches =>
                !isCreatePage && (
                  <GatsbyLink
                    to="/demo"
                    className={`link demo ${path.startsWith('/demo') &&
                      'selected'}`}
                  >
                    Essayer {matches ? 'gratuitement Campus' : ''}
                  </GatsbyLink>
                )
              }
            </MediaQuery>
          </Links>
          <div style={{ flexGrow: 1 }} />
          <div className="mobile-only" onClick={this.props.openMenu}>
            <i
              className="fas fa-bars"
              style={{ marginRight: '10px', cursor: 'pointer' }}
            />
          </div>
        </nav>
      </NavbarWrapper>
    );
  }
}

export default Navbar;
