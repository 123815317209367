import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';

import getPageContext from './getPageContext';

function withRoot<Props>(Component: React.ComponentType<Props>) {
  class WithRoot extends React.Component<Props> {
    public muiPageContext: any = null;

    constructor(props: any) {
      super(props);
      this.muiPageContext = props.muiPageContext || getPageContext();
    }

    public componentDidMount() {
      const jssStyles = document.querySelector('#server-side-jss');
      if (jssStyles && jssStyles.parentNode) {
        jssStyles.parentNode.removeChild(jssStyles);
      }
    }

    public render() {
      return (
        <MuiThemeProvider
          theme={this.muiPageContext.theme}
          sheetsManager={this.muiPageContext.sheetsManager}
        >
          <CssBaseline />
          <Component {...this.props} />
        </MuiThemeProvider>
      );
    }
  }

  return WithRoot;
}

export default withRoot;
