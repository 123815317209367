import styled from 'styled-components';
import theme from '../theme';

const Container = styled.div`
  max-width: 1100px;
  padding: 20px;
  padding-top: 80px;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.down('sm')} {
    padding-top: 30px;
  }
`;

export default Container;
