import { createMuiTheme } from '@material-ui/core/styles';
import { injectGlobal } from 'styled-components';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FF0066',
    },
    secondary: {
      main: '#FFCC33',
    },
    green: {
      main: '#66CC99',
    },
    blue: {
      main: '#003366',
    },
    orange: {
      main: '#FF6666',
    },
  },
});

injectGlobal`
body {
  overflow: auto!important;
  padding-right: 0!important;
}
body, html {
  background-color: transparent!important;
}

${theme.breakpoints.down('sm')} {
.desktop-only {
  display: none!important;
}
}

${theme.breakpoints.down('xs')} {
.desktop-tablet-only {
  display: none!important;
}
}

.mobile-only {
  display: none!important;
}

${theme.breakpoints.down('sm')} {
.mobile-only {
  display: unset!important;
}
}
`;

export default theme;
