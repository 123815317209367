import { Close } from '@material-ui/icons';
import GatsbyLink from 'gatsby-link';
import React from 'react';
import styled from 'styled-components';
import theme from '../theme';

const MenuWrapper = styled.div`
  position: fixed;
  z-index: 10000;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;

  ${theme.breakpoints.up('md')} {
    display: none;
  }
`;

const MenuLink = styled(GatsbyLink)`
  font-family: Roboto;
  font-size: 20px;
  margin-top: 20px;
  text-decoration: none;
  color: ${theme.palette.grey[700]};

  &:hover {
    color: ${theme.palette.primary.main};
  }
`;

const MenuLinkExternal = styled.a`
  font-family: Roboto;
  font-size: 20px;
  margin-top: 20px;
  text-decoration: none;
  color: ${theme.palette.grey[700]};

  &:hover {
    color: ${theme.palette.primary.main};
  }
`;

const Logo = styled.img`
  height: 50px;
  margin-bottom: 20px;

  ${theme.breakpoints.down('sm')} {
    height: 40px;
  }
`;

interface Props {
  isCreatePage?: boolean;
  closeMenu: () => void;
}

class Menu extends React.PureComponent<Props> {
  public render() {
    return (
      <MenuWrapper>
        <div
          style={{
            position: 'fixed',
            top: '25px',
            right: '25px',
            cursor: 'pointer',
          }}
          onClick={this.props.closeMenu}
        >
          <Close style={{ color: theme.palette.primary.main }} />
        </div>
        <GatsbyLink to="/" onClick={this.props.closeMenu}>
          <Logo src="https://lfai.co/static/logo_complet-6811c967177a20250b60188d1c26686d.png" />
        </GatsbyLink>
        <MenuLink to="/" onClick={this.props.closeMenu}>
          Accueil
        </MenuLink>
        <MenuLink to="/resources" onClick={this.props.closeMenu}>
          Ressources
        </MenuLink>
        <MenuLinkExternal
          href="https://medium.com/@1539768974147"
          target="_blank"
          onClick={this.props.closeMenu}
        >
          Blog
        </MenuLinkExternal>
        <MenuLink to="/contact" onClick={this.props.closeMenu}>
          Contact
        </MenuLink>
        <MenuLink
          to="/login"
          onClick={this.props.closeMenu}
          style={{ color: theme.palette.primary.main, marginTop: '40px' }}
        >
          Accédez à votre Campus
        </MenuLink>
      </MenuWrapper>
    );
  }
}

export default Menu;
